
export const exalt_payout = [
    [2250, 2000, 3000],
    [3750, 3000, 4500],
    [5250, 4500, 6000],
    [6750, 6000, 7500],
    [8250, 7500, 9000],
    [9750, 9000, 10500],
    [11250, 10500, 12000],
    [12750, 12000, 13500],
    [14250, 13500, 15000],
    [15750, 15000, 16500],
    [17250, 16500, 18000],
    [18750, 18000, 19500],
    [20250, 19500, 21000],
    [21750, 21000, 22500],
    [23250, 22500, 24000],
    [24750, 24000, 25500],
    [26250, 25500, 27000],
    [27750, 27000, 28500],
    [29250, 28500, 30000],
    [30750, 30000, 31500],
    [32250, 31500, 33000],
    [33750, 33000, 34500],
    [35250, 34500, 36000],
    [36750, 36000, 37500],
    [38250, 37500, 39000]
]